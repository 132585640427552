const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
import './styles/application.scss'

$(document).ready(function () {

  $('.select2').select2()

  $('#code_set_characters_restriction_type').change(function () {
    if ($(this).val() != 'no_restrictions') {
      $('.code_set_custom_excluded_characters').removeClass('hidden')
      $('.code_set_exclude_ambiguous_characters').removeClass('hidden')
    } else {
      $('.code_set_custom_excluded_characters').addClass('hidden')
      $('.code_set_exclude_ambiguous_characters').addClass('hidden')
    }
  });

  // force limit_type to one_time_ever for code sets of type store
  $('body').find('#new_code_set #code_set_store').change(function () {
    if ($(this).prop('checked') == true) {
      $('#code_set_limit_type').val('one_time_ever')
      $('#code_set_limit_type').prop('disabled', true)
    } else {
      $('#code_set_limit_type').prop('disabled', false)
    }
  })

  $('#code_set_limit_type').change(function () {
    if ($(this).val() == 'custom') {
      $('.form-group.code_set_limit_total').show();
      $('.form-group.code_set_limit_per_client').show();
    } else {
      $('.form-group.code_set_limit_total').hide();
      $('.form-group.code_set_limit_per_client').hide();
    }
  });

  if ($('#code_set_limit_type').val() == 'custom') {
    $('.form-group.code_set_limit_total').show();
    $('.form-group.code_set_limit_per_client').show();
  } else {
    $('.form-group.code_set_limit_total').hide();
    $('.form-group.code_set_limit_per_client').hide();
  }

  // $.datetimepicker.setLocale('it');

  // $('.date-field').DateTimePicker({
  //   mode: "date"
  //   // format: '<%= I18n.t('js.datetimepicker.formats.date') %>'
  // });

});
